<div class="content">
  <div class="content-content">
    <ng-container *ngIf="title || subtitle">
      <ng-container *ngIf="title">
        <wlx-heading>
          <wlx-heading-lead x1>
            {{ title }}
          </wlx-heading-lead>
        </wlx-heading>
      </ng-container>
      <ng-container *ngIf="subtitle">
        <br />
        <div class="code-note">
          {{ subtitle }}
        </div>
      </ng-container>
      <br />
      <br />
      <br />
    </ng-container>
    <div class="bill-table-header">
      <div class="bill-table-header--article grid-padding-offset">Artikel</div>
      <div class="bill-table-header--amount">Menge</div>
      <div class="bill-table-header--price">Preis</div>
    </div>
    <br />
    <div class="bill-table-articles">
      <ng-container *ngFor="let item of items">
        <div class="bill-table-article-select-container">
          <div class="bill-table-article">
            <div class="bill-table-article-presence">
              <div class="bill-table-article-image-view">
                <img class="bill-table-article-image" [src]="item.imageSrc" alt="" />
              </div>
              <div class="bill-table-article-details">
                <div class="bill-table-article-name">
                  {{ item.name }}
                </div>
                <div class="bill-table-article-type">
                  {{ item.category }}
                </div>
              </div>
            </div>
            <div class="bill-table-article-amount grid-padding-offset">
              {{ item.amount }}
            </div>
            <div class="bill-table-article-price grid-padding-offset">
              {{ (item.centsPerUnit * item.amount) / 100 | number : '1.2-2' : 'de' }}€
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
